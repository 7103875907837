.dropdown2 {
  position: relative;
  display: flex;
}

.dropdown-content2 {
  display: none;
  position: absolute;
  /* background-color: #f9f9f9; */
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  left: -5%;
  margin: 10px;
  top:80%
}

.dropdown2.active .dropdown-content2 {
  display: block;
}
.dropdown2.active .up-arrow-drop2{
  display: inline;
}
.dropdown2.active .down-arrow-drop2{
  display: none;
}
.up-arrow-drop2{
  display: none;
  transform: rotate(180deg);
}
.down-arrow-drop2{
  display: inline;
}
.polygonArrow {
  position: absolute;
  top: -5%;
  left: 45%;
}
